<template>
  <div>
    <a-row type="flex" justify="center">
      <a-col :span="23" :lg="16">
        <a-row
          style="
            background-color: #ffffff;
            box-shadow: 0px 0px 17px -8px rgba(14, 155, 210, 20);
            border-radius: 8px;
          "
          :style="{ padding: fullWidth > 992 ? '20px 30px' : '20px 10px' }"
        >
          <a-row>
            <a-col :span="24">
              <el-page-header
                style="
                  width: 100%;
                  height: 50px;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  margin-left: 10px;
                "
                @back="backLegal"
                content="竞赛详情"
              >
              </el-page-header>
              <!-- 专题课程列表数据 -->
              <template v-if="empty">
                <a-row
                  type="flex"
                  justify="center"
                  style="margin-bottom: 10px; margin-top: 10px; min-height: 400px"
                >
                  <el-empty :description="description"></el-empty>
                </a-row>
              </template>
              <template v-else>
                <a-row
                  :gutter="24"
                  style="min-height: 500px; margin: 0"
                  v-loading="loading"
                  element-loading-text="加载中"
                  element-loading-spinner="el-icon-loading"
                  element-loading-background="rgba(0, 0, 0, 0.2)"
                >
                  <!-- 详情内容 -->
                  <div class="special-class-details">
                    <!-- 专题课程详情 -->
                    <div style="width: 100%">
                      <div
                        class="details-header"
                        v-for="(item, index) in info.multiMediaList"
                        :key="index"
                      >
                        <!-- type 为1 -->
                        <img
                          v-if="item.type === 1"
                          :src="item.content"
                          alt=""
                          class="banner-img"
                        />
                        <!-- type 为2 -->
                        <vueMiniPlayer
                          v-if="item.type === 2"
                          ref="vueMiniPlayer"
                          :video="video"
                          :mutex="true"
                          @fullscreen="handleFullscreen"
                          style="border-radius: 8px"
                        />
                      </div>
                      <div class="pform--details">
                        <div class="pform--details--title">
                          {{ info.topic }}
                        </div>
                      </div>
                      <div class="pform--user">
                        <div class="pform--user--name">{{ info.startDate }} 至</div>
                        <div class="pform--user--time">
                          {{ info.endDate }}
                        </div>
                        <div class="pform--user--count">
                          {{ info.participatingCount }}人已参加
                        </div>
                      </div>
                      <!-- 成绩查询 -->
                      <div
                        class="pform--user"
                        v-if="
                          (openStatus == 1 || openStatus == -1) && info.enableQueryScore
                        "
                      >
                        <div
                          v-if="
                            (openStatus == 1 || openStatus == -1) && info.enableQueryScore
                          "
                          @click="viewGrades()"
                          style="
                            width:50%
                            border:1px solid #f7c1c1;
                            border-radius: 10px;
                            padding: 4px;
                            cursor: pointer;
                            background-image: linear-gradient(to left,#f7c1c1, #fce7e7);
                          "
                        >
                          <img
                            src="/images/score-query.png"
                            alt=""
                            style="height: 36px"
                          />
                          <span
                            style="
                              height: 100%;
                              display: inline-block;
                              color: #fd6161;
                              font-weight: bold;
                              font-size: 16px;
                              margin-left: 15px;
                            "
                            >成绩查询</span
                          >
                        </div>
                        <!-- 获奖名单 -->
                        <div
                          v-if="openStatus == -1 && info.enableQueryScore"
                          @click="viewAward()"
                          style="
                            width:50%
                            border:1px solid #f7c1c1;
                            border-radius: 10px;
                            padding: 4px;
                            cursor: pointer;
                            background-image: linear-gradient(to right,#FCF1DA, #FCDD9B);
                          "
                        >
                          <img src="/images/Award-icon.png" alt="" style="height: 36px" />
                          <span
                            style="
                              height: 100%;
                              display: inline-block;
                              color: #fd6161;
                              font-weight: bold;
                              font-size: 16px;
                              margin-left: 15px;
                            "
                            >获奖名单</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 活动介绍 -->
                  <div class="pform--details">
                    <xmp>
                      <div class="pform--details--title" style="white-space: pre-wrap">
                        {{ info.desc }}
                      </div>
                    </xmp>
                  </div>

                  <!-- 去答题 -->
                  <div
                    style="width: 100%; margin: 30px auto 0 auto; padding-bottom: 30px"
                  >
                    <button
                      :disabled="openStatus != 1 ? true : false"
                      :class="{
                        testing: openStatus == 1,
                        ending: openStatus != 1,
                      }"
                      @click="goTest()"
                    >
                      {{
                        openStatus == 1
                          ? "开始答题"
                          : openStatus == 0
                          ? "即将开始"
                          : "已结束"
                      }}
                    </button>
                  </div>
                </a-row>
              </template>
            </a-col>
          </a-row>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { info } from "console";
import { mapState } from "vuex";
export default {
  created() {
    this.resultCanSee = this.$route.query.resultCanSee;
    this.getLearnDetails();
  },
  data() {
    return {
      loading: true,
      empty: false,
      info: {},
      description: "",
      resultCanSee: false,
      app_no: "", // 区分专题课程答题和学法竞赛答题答完后页面跳转

      // 视频
      videoParams: {
        id: "video",
        url: undefined,
        cover: "",
        muted: false,
        loop: false,
        preload: "auto",
        poster: "",
        volume: 1,
        autoplay: false,
        currentTime: "",
        // crossOrigin: true,
      },
    };
  },
  computed: {
    ...mapState(["fullWidth"]),
    openStatus() {
      console.log(this.info);
      if (this.isEmpty(this.info.startDate) || this.isEmpty(this.info.endDate)) {
        console.log("结果:-1");
        return -1;
      }
      let startTime = new Date(
        Date.parse(this.info.startDate.replace(/-/g, "/"))
      ).getTime();
      let endTime = new Date(Date.parse(this.info.endDate.replace(/-/g, "/"))).getTime();
      let nowTime = new Date().getTime();
      //0 没有开始1 开始 -1 结束了
      let result = nowTime < startTime ? 0 : nowTime <= endTime ? 1 : -1;
      console.log("结果:", result);
      return result;
    },
  },
  methods: {
    handleFullscreen() {},
    getLearnDetails() {
      this.$HTTP
        .httpToken({
          url: `/wst/testPaper/topicInfo/${this.$route.query.topicId}`,
          method: "post",
          data: {},
        })
        .then((res) => {
          console.log("法治比赛详情", res);
          this.loading = false;
          if (res.code == 0 && res.data) {
            this.empty = false;
            this.info = res.data;
            this.resultCanSee = res.data.resultCanSee;
            this.app_no = res.data.app_no;
            console.log("this.info :>> ", this.info);
            // 对视频进行判断
            if (res.data.multiMediaList[0].type === 2) {
              this.videoParams.cover = this.info.multiMediaList[0].cover;
              this.videoParams.url = this.info.multiMediaList[0].content;
            }
          } else {
            this.empty = true;
            this.description = "暂无数据";
          }
        })
        .catch((e) => {
          this.loading = false;
          this.empty = true;
          this.description = e;
        });
    },
    backLegal() {
      this.$router.back();
    },
    // 去测试
    goTest() {
      this.$router.push({
        path: "/specialclass/testregister",
        query: {
          specialId: this.$route.query.topicId,
          app_no: this.app_no,
        },
      });
    },
    // 查看成绩
    viewGrades() {
      this.$router.push({
        path: "/legalcompetition/viewsgrades",
        query: { topicId: this.$route.query.topicId },
      });
    },
    // 查看获奖名单
    viewAward() {
      if (this.info.awardAnnouncementFlag) {
        this.$router.push({
          path: "/legalcompetition/awardlist",
          query: {
            topicId: this.$route.query.topicId,
          },
        });
        localStorage.setItem(
          "awardHtml",
          JSON.stringify(this.info.awardAnnouncementContent)
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.banner-img {
  // height: 100%;
  aspect-ratio: 16/9;
  overflow: hidden;
  border-radius: 10px;
  width: 100%;
}

.special-class-details {
  width: 100%;
  height: auto;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pform {
  width: 100%;
  height: auto;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &--user {
    height: 60px;
    width: 100%;
    display: flex;
    padding: 0 10px;
    box-sizing: border-box;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgba(240, 240, 240, 100);

    &--avatar {
      width: 40px;
      height: 40px;
    }

    &--name {
      margin-left: 10px;
      color: #101010;
      font-size: 16px;
      font-weight: 700;
    }

    &--time {
      margin-left: 10px;
      color: #101010;
      font-size: 16px;
      font-weight: 700;
    }

    &--count {
      flex: 1;
      text-align: right;
      color: #cbcbcd;
      font-size: 14px;
    }
  }

  &--details {
    display: flex;
    flex-direction: column;
    margin-bottom: 6px;
    width: 100%;

    &--title {
      width: 100%;
      color: #333;
      font-size: 16px;
      font-family: SourceHanSansCN-Regular;
      font-weight: 500;
      line-height: 50px;
      text-align: left;
      padding: 0 10px;
      box-sizing: border-box;
    }

    &--description {
      text-align: left;
      padding: 0 10px;
    }
  }
}

@media screen and (max-width: 800px) {
  .pform {
    width: 100%;
  }
}

@media screen and (min-width: 800px) {
  .pform {
    width: 70%;
  }
}

.testing {
  border-radius: 25px;
  background: linear-gradient(90deg, #51d2be, #0090d7);
  font-size: 15px;
  color: #fefefe;
  font-weight: bold;
  height: 50px;
  width: 340px;
  border: none;
  cursor: pointer;
}

.ending {
  border-radius: 25px;
  background: #666;
  font-size: 15px;
  color: #eee;
  font-weight: bold;
  height: 50px;
  width: 340px;
  border: none;
  cursor: not-allowed;
}
</style>
